function fillConfirmation() {
    var compagny = document.querySelector('input[name=company]').value;
	var fName = document.querySelector('input[name=fname]').value;
	var lName = document.querySelector('input[name=lname]').value;
	var email = document.querySelector('input[name=email1]').value;
	var zip = document.querySelector('input[name=zip]').value;
	var city = document.querySelector('input[name=city]').value;
	var room = document.querySelector('.choosen-room-js').innerHTML;

	var dateTime = getCheckedInputs();
	dateTime.splice(1, 0, " - ");
	dateTime = dateTime.toString().replace(/,/g, '');

    var date = moment(
        document.querySelector('.date-breadcrumb-js').dataset.date
    ).format('DD-MM-YYYY');

	var priceCurrency = 'â¬' + calculatePrice();

	var countrySelect = document.querySelector('select[name=country]');
	var country = countrySelect.options[countrySelect.selectedIndex].text;

	var fullName = fName + " " + lName;
	var fullAddress = city + ", " + zip + ", " + country;

	document.querySelector('.guest-name').innerHTML = fullName;
    document.querySelector('.guest-company').innerHTML = compagny;
	document.querySelector('.guest-address').innerHTML = fullAddress;
	document.querySelector('.guest-phone').innerHTML = phoneNumber.getNumber();
	document
		.querySelectorAll('.guest-email')
		.forEach(function(e) {
			e.innerHTML = email;
		});
	document.querySelector('.booked-room').innerHTML = room;
	document.querySelector('.checkin-date').innerHTML = date;
	document.querySelector('.reservation-time').innerHTML =dateTime;
	document.querySelector('.total-price').innerHTML = priceCurrency;
	document.querySelector('.booked-room-price').innerHTML = priceCurrency + ' incl. VAT';
	document.querySelector('.total-price-payment').innerHTML = priceCurrency;
}
